import { createStore } from 'vuex';

import accessSchedules from '@/store/modules/accessSchedules';
import addressLookup from '@/store/modules/addressLookup';
import areas from '@/store/modules/areas';
import deviceFirmware from '@/store/modules/deviceFirmware';
import deviceGroups from '@/store/modules/deviceGroups';
import timeZone from '@/store/modules/timeZone';
import devices from '@/store/modules/devices';
import globalConfigurations from '@/store/modules/globalConfigurations';
import licence from '@/store/modules/licence';
import sites from '@/store/modules/sites';
import userGroups from '@/store/modules/userGroups';
import users from '@/store/modules/users';
import tools from '@/store/modules/tools';
import activities from '@/store/modules/activities';
import heartbeats from '@/store/modules/heartbeats';
import tags from '@/store/modules/tags';
import userTags from '@/store/modules/userTags';
import clockings from '@/store/modules/clockings';
import emails from '@/store/modules/emails';
import deviceStatusAlertConfigurations from '@/store/modules/deviceStatusAlertConfigurations';

const store = createStore({
  modules: {
    accessSchedules,
    addressLookup,
    areas,
    deviceFirmware,
    deviceGroups,
    devices,
    globalConfigurations,
    licence,
    sites,
    userGroups,
    users,
    timeZone,
    tools,
    activities,
    heartbeats,
    tags,
    userTags,
    clockings,
    emails,
    deviceStatusAlertConfigurations,
  },
});

export default store;
