import { EmailClient, EmailDto } from '@/apiClients';
import authorizedClient from './authorizedClient';

export interface IGetEmailPayload {
  emailAddresses: string;
  email: EmailDto;
}

export interface IEmailState {
  email: EmailDto;
}

export default {
  namespaced: true as true,
  state: () =>
    ({
      email: new EmailDto(),
    }) as IEmailState,

  mutations: {
    SET_CLOCKING_RESEND(state: IEmailState, email: EmailDto) {
      state.email = email;
    },
  },

  actions: {
    async send({ commit }: any, data: IGetEmailPayload) {
      const emailClient = await authorizedClient(EmailClient);
      try {
        const emailResult = await emailClient.send(
          data.emailAddresses,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION,
          data.email
        );
      } catch (error) {
        throw error;
      }
    },
  },
};
