import { ClockingsClient, ClockingResendDto } from '@/apiClients';

import authorizedClient from './authorizedClient';

export interface IToolsState {
  clockingResend: ClockingResendDto;
}

export default {
  namespaced: true as true,
  state: () =>
    ({
      clockingResend: new ClockingResendDto({ remaining: 0, continuationSkipOffset: 0 }),
    }) as IToolsState,

  getters: {
    remainingCount: (state: IToolsState) => () => state.clockingResend.remaining,
    continuationSkipOffset: (state: IToolsState) => () => state.clockingResend.continuationSkipOffset,
  },

  mutations: {
    SET_CLOCKING_RESEND(state: IToolsState, clockingResend: ClockingResendDto) {
      (state.clockingResend.remaining = clockingResend.remaining),
        (state.clockingResend.continuationSkipOffset = clockingResend.continuationSkipOffset);
    },
  },

  actions: {
    async resendClockingData({ commit }: any, data: any) {
      const clockingClient = await authorizedClient(ClockingsClient);
      try {
        const resendDataResult = await clockingClient.resendClockings(
          data.startDate,
          data.endDate,
          data.skipOffset,
          data.pageSize,
          process.env.VUE_APP_TERMINAL_WEB_API_VERSION
        );
        commit('SET_CLOCKING_RESEND', resendDataResult?.result);
      } catch (error) {
        throw error;
      }
    },
  },
};
